import { styled } from "styled-components";

export const StyledButton = styled.button`
  width: auto;
  height: 49px;
  padding: 0 20px;
  background-color: #004283;
  color: white;
  font-weight: 600;
  letter-spacing: 3px;
  border: none;
  cursor: pointer;
`;
