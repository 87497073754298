import React from "react";
import { createContext, useCallback, useContext, useState } from "react";
import { Icons } from "./icons";

export const IconsContext = createContext(null);

export const IconsProvider = ({ children }) => {
  const [icons] = useState(Icons);

  const getIcon = useCallback((name) => icons[name], [icons]);

  return (
    <IconsContext.Provider value={{ getIcon }}>
      {children}
    </IconsContext.Provider>
  );
};

const useIcon = (name) => {
  const context = useContext(IconsContext);

  if (context === null) {
    throw new Error("useIcon must be used within a IconsProvider");
  }

  return context.getIcon(name);
};

export const Icon = ({
  style,
  className,
  size = "1em",
  width,
  height,
  fill = "currentColor",
  onClick,
  name,
}) => {
  const { viewBox, svg } = useIcon(name);

  return (
    <svg
      height={height ?? size}
      width={width ?? size}
      viewBox={viewBox}
      className={className}
      style={style}
      onClick={onClick}
      fill="none"
    >
      {svg(fill)}
    </svg>
  );
};
