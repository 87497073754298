import styled from "styled-components";

export const Container = styled.section`
  width: 100%;
  background: #004283;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledImage = styled.img`
  width: 152px;
  height: 152px;
`;

export const ContainerFooter = styled.section`
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
export const ContainerNewsletter = styled.section`
  display: none;
  flex-direction: column;
  justify-content: center;
  border-bottom: 1px solid #fff;

  .Newsletter {
    color: #fff;
    font-family: Montserrat;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.25px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;

export const ContainerInformation = styled.section`
  display: flex;
  flex-direction: column-reverse;
  row-gap: 44px;

  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 500px 1fr;
    grid-gap: 10px;
  }
`;

export const ContainerCopyright = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 31px;
  padding-bottom: 31px;
  border-top: 1px solid #fff;

  .Copyright {
    color: #fff;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.75px;
  }
`;

export const ContainerContacto = styled.section`
  display: flex;
  flex-direction: column;
  gap: 25px;
  justify-content: center;
  margin-bottom: 30px;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
    padding-right: 31px;
  }
`;

export const ContainerClub = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-bottom: 1px solid #fff;

  .description {
    color: #fff;
    text-align: center;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.75px;
    text-align: start;
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) {
    padding-left: 30px;
    padding-right: 30px;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
    border-bottom: none;

    .description {
      text-align: justify;
      text-align-last: center;
    }
  }
`;
export const ContainerPages = styled.section`
  display: none;
  flex-direction: column;
  justify-content: center;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 35px;
  padding-bottom: 35px;
  .page {
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 45px; /* 281.25% */
    letter-spacing: 1.6px;
  }

  @media screen and (min-width: 768px) {
    display: flex;
  }
`;
