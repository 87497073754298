import * as React from "react";
import { Container } from "./SectionInformation.style";

export const SectionInformation = ({ tittle, description }) => {
  return (
    <div>
      <Container>
        <h1 className="tittle">{tittle}</h1>
        <h2 className="description">{description}</h2>
      </Container>
    </div>
  );
};
