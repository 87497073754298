import styled from "styled-components";

export const WhatsappButtonStyle = styled.a`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #25d366;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    color: #fff;
    font-size: 30px;
  }

  &:hover {
    background-color: #fff;

    svg {
      color: #25d366;
    }
  }
`;
