import styled from "styled-components";

export const NavbarContainer = styled.nav`
  position: absolute;
  z-index: 999;
  background-color: transparent;
  transition: all 0.5s ease-in-out;
  width: 100%;

  &.scrolled {
    top: 0;
    position: sticky;
    background-color: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .navbar {
      &__logo {
        &__burger {
          svg {
            color: var(--primary-color);
          }
        }
      }

      &__menu {
        color: black;

        &__item {
          color: black;
        }
      }
    }
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    padding: 20px 20px;

    &__logo {
      display: flex;
      align-items: center;

      &__burger {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: fit-content;

        svg {
          font-size: 40px;
          color: white;
        }

        @media screen and (min-width: 1024px) {
          display: none;
        }
      }

      img {
        width: 78px;
        height: 78px;
      }
    }

    &__menu {
      display: none;
      align-items: center;
      justify-content: start;
      margin-right: 50px;
      margin-left: 50px;
      font-weight: 500;
      color: white;

      &__item {
        color: white;
        text-decoration: none;
        font-size: 18px;
        margin: 0 20px;

        &.active {
          color: var(--secondary-color);
        }

        &:hover {
          cursor: pointer;
          color: var(--secondary-color);
        }
      }

      @media screen and (min-width: 1024px) {
        display: flex;
      }
    }

    &__button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .mobile__menu {
    padding-bottom: 20px;
    background-color: white;
    opacity: ${(props) => (props.open ? "1" : "0")};
    display: ${(props) => (props.open ? "block" : "none")};
    transition: opacity 0.5s ease-in-out;
    
      

    ul {
      margin-top: 0;
      list-style: none;
      li{
        &.active {
          color: var(--secondary-color);
        }

        &:hover {
          cursor: pointer;
          color: var(--secondary-color);
        }
      }
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
`;
