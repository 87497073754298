import React, { useEffect, useState } from "react";
import { NavbarContainer } from "./Navbar.style";
import logo from "../../images/logoclub.png";
import Button from "../Button";
import { globalHistory } from "@reach/router";
import { Link, navigate } from "gatsby";
import { Icon } from "../../contexts/IconsContext/iconsContext";

const Navbar = (props) => {
  const path = globalHistory.location.pathname;
  const [scroll, setScroll] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const changeBackground = () => {
    if (window.scrollY >= 800) {
      setScroll(true);
    } else {
      setScroll(false);
    }
  };

  const goTo = (ruta) => {
    navigate(ruta);
  };

  // Cuando se monte el componente se implmenta el evento o función.
  useEffect(() => {
    window.addEventListener("scroll", changeBackground);
  }, []);

  // Cuando se desmonte el componente se elimina el evento o función.
  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  return (
    <NavbarContainer open={menuOpen} className={`${scroll ? "scrolled" : ""}`}>
      <div className="navbar">
        <div className="navbar__logo">
          <div
            className="navbar__logo__burger"
            onClick={() => setMenuOpen((prev) => !prev)}
          >
            <Icon name="burger" />
          </div>
          <img src={logo} alt="" className="logo" />
        </div>
        <div className="navbar__menu">
          <Link
            to="/"
            className={`navbar__menu__item ${path === "/" ? "active" : ""}`}
          >
            Inicio
          </Link>
          <Link
            to="/nosotros"
            className={`navbar__menu__item ${path === "/nosotros/" ? "active" : ""
              }`}
          >
            Nosotros
          </Link>
          <Link
            to="/deportes"
            className={`navbar__menu__item ${path.includes("/deportes/") ? "active" : ""
              }`}
          >
            Deportes
          </Link>
          <Link
            to="/servicios"
            className={`navbar__menu__item ${path === "/servicios/" ? "active" : ""
              }`}
          >
            Servicios
          </Link>
          <Link
            to="/socios"
            className={`navbar__menu__item ${path === "/socios/" ? "active" : ""
              }`}
          >
            Socios
          </Link>
          <Link
            to="/pqrs"
            className={`navbar__menu__item ${path === "/pqrs/" ? "active" : ""
              }`}
          >
            PQRS
          </Link>
        </div>
        <div className="navbar__button">
          <Button onClick={() => goTo("/membresias")}> HAZTE SOCIO</Button>
        </div>
      </div>
      <div className="mobile__menu">
        <ul>
          <li onClick={() => goTo("/nosotros/")}>Nosotros</li>
          <li onClick={() => goTo("/deportes/")}>Deportes</li>
          <li onClick={() => goTo("/servicios/")}>Servicios</li>
          <li onClick={() => goTo("/socios/")}>Socios</li>
          <li onClick={() => goTo("/pqrs/")}>PQRS</li>
        </ul>
      </div>
    </NavbarContainer>
  );
};

export default Navbar;
