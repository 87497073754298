import "../../../assets/css/global.css";
import React from "react";
import Navbar from "../../Navbar";
import { IconsProvider } from "../../../contexts/IconsContext/iconsContext";

import { Footer } from "../../Footer";
import { WhatsappButton } from "../../WhatsappButton/WhatsappButton";
import { PseButton } from "../../PSEButton/PseButton";

const DefaultLayout = ({ children }) => {
  return (
    <div>
      <IconsProvider>
        <Navbar />
        {children}
        <Footer></Footer>
        <WhatsappButton />
        <PseButton />
      </IconsProvider>
    </div>
  );
};

export default DefaultLayout;
