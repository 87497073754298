import React from "react";
import pse from "../../images/pse.png";
import { PseButtonStyle } from "./PseButton.style";

export const PseButton = () => {
  return (
    <PseButtonStyle
      href="http://www.zonapagos.com/t_Clubcampestrepopayan"
      target="__blank"
    >
      <img
        src={pse}
        alt="Image pse"
        style={{ borderRadius: "50%", width: "70px", height: "70px" }}
      ></img>
    </PseButtonStyle>
  );
};
