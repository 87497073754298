import { styled } from "styled-components";
export const Container = styled.section`
  align-items: start;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  text-align: start;

  .tittle {
    color: #fff;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.8px;
  }
  .description {
    color: #fff;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.4px;
  }
`;
