import React from "react";
import { Icon } from "../../contexts/IconsContext/iconsContext";
import { WhatsappButtonStyle } from "./WhatsappButton.style";

export const WhatsappButton = () => {
  return (
    <WhatsappButtonStyle
      href="
      https://wa.link/nax4ri"
      target="__blank"
    >
      <Icon name="whatsapp" />
    </WhatsappButtonStyle>
  );
};
