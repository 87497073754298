import React from "react";
import Logo from "../../images/logoclub.png";
import {
  Container,
  ContainerFooter,
  ContainerNewsletter,
  ContainerInformation,
  ContainerCopyright,
  ContainerClub,
  ContainerPages,
  ContainerContacto,
  StyledImage,
} from "./index.style";

import { SectionInformation } from "./SectionInformation";

export const Footer = () => {
  return (
    <Container>
      <ContainerFooter>
        <ContainerNewsletter />
        <ContainerInformation>
          <ContainerContacto>
            <SectionInformation
              tittle="Calle 40 Norte # 5 - 102"
              description="Barrio La Ximena"
            />
            <SectionInformation
              tittle="Número de celular"
              description="+57 317-586-0784"
            />
            <SectionInformation
              tittle="Correo electrónico"
              description="comunicaciones@clubcampestrepopayan.com"
            />
            <SectionInformation
              tittle="Facebook"
              description="Corporación Club Campestre de Popayán "
            />
            <SectionInformation tittle="Instagram" description="@CCPopayan " />
          </ContainerContacto>
          <ContainerClub>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <StyledImage src={Logo} alt="Foto logo" />
            </div>
            <h1 className="description">
              El Club Campestre de Popayán es un exclusivo lugar que combina un
              entorno natural y privilegiado, con instalaciones y servicios
              diseñados para brindar una experiencia única y placentera a sus
              socios.
            </h1>
          </ContainerClub>
          <ContainerPages>
            <h1 className="page">Inicio</h1>
            <h1 className="page">Nosotros</h1>
            <h1 className="page">Deportes</h1>
            <h1 className="page">Servicios</h1>
            <h1 className="page">Socios</h1>
            <h1 className="page">PQRS</h1>
          </ContainerPages>
        </ContainerInformation>
        <ContainerCopyright>
          <h1 className="Copyright">
            Copyright ©{new Date().getFullYear()} All rights reserved.
          </h1>
        </ContainerCopyright>
      </ContainerFooter>
    </Container>
  );
};
