exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-index-js": () => import("./../../../src/pages/contacto/index.js" /* webpackChunkName: "component---src-pages-contacto-index-js" */),
  "component---src-pages-deportes-golf-index-js": () => import("./../../../src/pages/deportes/golf/index.js" /* webpackChunkName: "component---src-pages-deportes-golf-index-js" */),
  "component---src-pages-deportes-index-js": () => import("./../../../src/pages/deportes/index.js" /* webpackChunkName: "component---src-pages-deportes-index-js" */),
  "component---src-pages-deportes-otros-index-js": () => import("./../../../src/pages/deportes/otros/index.js" /* webpackChunkName: "component---src-pages-deportes-otros-index-js" */),
  "component---src-pages-deportes-piscina-index-js": () => import("./../../../src/pages/deportes/piscina/index.js" /* webpackChunkName: "component---src-pages-deportes-piscina-index-js" */),
  "component---src-pages-deportes-tenis-index-js": () => import("./../../../src/pages/deportes/tenis/index.js" /* webpackChunkName: "component---src-pages-deportes-tenis-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-membresias-index-js": () => import("./../../../src/pages/membresias/index.js" /* webpackChunkName: "component---src-pages-membresias-index-js" */),
  "component---src-pages-nosotros-index-js": () => import("./../../../src/pages/nosotros/index.js" /* webpackChunkName: "component---src-pages-nosotros-index-js" */),
  "component---src-pages-pqrs-index-js": () => import("./../../../src/pages/pqrs/index.js" /* webpackChunkName: "component---src-pages-pqrs-index-js" */),
  "component---src-pages-servicios-index-js": () => import("./../../../src/pages/servicios/index.js" /* webpackChunkName: "component---src-pages-servicios-index-js" */),
  "component---src-pages-socios-index-js": () => import("./../../../src/pages/socios/index.js" /* webpackChunkName: "component---src-pages-socios-index-js" */),
  "component---src-pages-socios-listadecanjes-index-js": () => import("./../../../src/pages/socios/listadecanjes/index.js" /* webpackChunkName: "component---src-pages-socios-listadecanjes-index-js" */),
  "component---src-pages-socios-noticia-index-js": () => import("./../../../src/pages/socios/noticia/index.js" /* webpackChunkName: "component---src-pages-socios-noticia-index-js" */)
}

